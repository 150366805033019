:root {
  --primary: #6222cc;
  --secondary: #096647;
  --light: #f6f4f9;
  --dark: #04000b;
}

@media only screen and (max-width: 600px) {
  .Header {
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .Body {
    position: fixed;
    top: 112px;
    bottom: 72px;
    right: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
  }
  .Footer {
    position: fixed;
    height: fit-content;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }

  .Donation {
    position: fixed;
    padding: 0 5px;
    height: 50px;
    top: 60px;
    width: 100%;
    background-color: rgba(250, 235, 215, 0.852);
    border-radius: 20px;
    text-align: center;
    overflow: hidden;
    word-break: break-all;
  }
}

@media only screen and (min-width: 600px) {
  .Header {
    position: fixed;
    top: 0;
    z-index: 5;
  }
  .Donation {
    position: fixed;
    top: 60px;
    width: 100%;
    background-color: rgba(250, 235, 215, 0.852);
    text-align: center;
    margin: auto;
    border-radius: 20px;
  }

  .Body {
    position: absolute;
    top: 70px;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .Footer {
  }
}

.fill-x-size {
  width: 100%;
}

.blockSelection {
  user-select: none;
}

.centerxy {
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.maxXY {
  width: 100%;
  height: 100%;
}

.textColor-secondary {
  color: var(--secondary) !important;
}
